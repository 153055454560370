<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <!-- <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" name="deptCd" :plantCd="plantCd" v-model="searchParam.deptCd" />
        </div> -->
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="사용자명"
            name="userName"
            v-model="searchParam.userName">
          </c-text>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="userTable"
      title="도급업체 사용자 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="userId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'user-vendor-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          innerFlag: 'N',
          deptCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'userName',
            field: 'userName',
            style: 'width:150px',
            label: '성명',
            align: 'center',
            sortable: true
          },
          {
            name: 'jobName',
            field: 'jobName',
            style: 'width:80px',
            label: '직책',
            align: 'center',
            sortable: true
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            style: 'width:80px',
            align: 'center',
            sortable: true
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            style: 'width:130px',
            label: '생년월일',
            align: 'center',
            sortable: true
          },
          {
            name: 'sexName',
            field: 'sexName',
            style: 'width:90px',
            label: '성별',
            align: 'center',
            sortable: true
          },
          {
            name: 'mobileNo',
            field: 'mobileNo',
            label: '연락처',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'email',
            field: 'email',
            label: '이메일',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
      searchParam: {
        deptCd: null,
        userName: '',
        retireFlag: 'N',
        innerFlag: 'N',
        searchFlag: 'Y',
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    plantCd() {
      return this.searchParam.plantCd || this.popupParam.plantCd
    }
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.user.vendor.list.url;
      // code setting
      if (this.popupParam.deptCd) {
        this.searchParam.deptCd = this.popupParam.deptCd;
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['userTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '사용자를 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
